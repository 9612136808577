import './App.css';
import Footer from './Components/Footer/Footer';
import Hero from './Components/Hero/Hero';
import Join from './Components/Join/Join';
import Plans from './Components/Plans/Plans';
import Programs from './Components/Programs/Programs';
import Reason from './Components/Reason/Reason';
import Testimonial from './Components/Testimonial/Testimonial';

function App() {
  return (
  
  <div className='App'>
        <Hero />
        <Programs />
        <Reason />
        <Plans/>
        <Testimonial/>
        <Join/>
        <Footer/>

  </div>
    );

}

export default App;
