import React, { useState } from "react";
import "./Testimonial.css";
import { testimonialsData } from "../../data/testimonialsData";
import leftArrow from "../../picture/leftArrow.png";
import rightArrow from "../../picture/rightArrow.png";
import {motion} from 'framer-motion'

const Testimonial = () => {
  const transition={type:'spring',duration : 3}
  
  const [select, setSelect] = useState(0);
  const tLength = testimonialsData.length;

  return (
    <div className="testimonials" id="testmonials">
      <div className="left-t">
        <span> Testimonials</span>
        <span className="stroke-text"> What they </span>
        <span>say about us</span>
        <motion.span
         key={select}
         initial={{opacity:0, x: -100}}
         animate={{opacity:1,x:0}}
         transition={transition}
         exit={{opacity: 0 , x:-100}}
        
        >{testimonialsData[select].review}</motion.span>
        <span
          style={{
            color: "var(--orange)",
          }}
        >
          {testimonialsData[select].name}
        </span>{" "}
        -{testimonialsData[select].status}
      </div>

      <div className="right-t">
          <motion.div
            
          ></motion.div>
          
          
          <motion.div
            initial={{opacity:0, x: 100}}
            whileInView={{opacity:1,x:0}}
            transition={transition}
          ></motion.div>


        <motion.img 
        key={select}
        initial={{opacity:0, x: 100}}
        animate={{opacity:1,x:0}}
        transition={transition}
        exit={{opacity: 0 , x:-100}}
        src={testimonialsData[select].image} />
        <div className="arrow">
          <img onClick={()=>
        {
            select===0?setSelect(tLength -1):setSelect((prev) =>prev -1)
        }} src={leftArrow} />


        <img src={rightArrow} onClick={()=>
        {
            select===tLength -1 ? setSelect(0) : setSelect((prev)=> prev+1)
        }} />
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
