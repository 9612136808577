import React from 'react'
import './Reason.css'
import image1 from '../../picture/image1.png'
import image2 from '../../picture/image2.png'
import image3 from '../../picture/image3.png'
import image4 from '../../picture/image4.png'
import nb from '../../picture/nb.png'
import nike from '../../picture/nike.png'
import adidas from '../../picture/adidas.png'
import tick from '../../picture/tick.png'





const Reason = () => {
  return (
    <div className='Reasons' id='reasons'>

      <div className='left-r'>
        <img src={image1}  />
        <img src={image2}  />
        <img src={image3}  />
        <img src={image4}  />


      </div>

      <div className='right-r'>
        <span>Some reasons</span>
       
        <div>
          <span className='stroke-text'>Why</span>
          <span> choose us ?</span>

        </div>
       
        <div className='details-r'>
              <div>
                <img src={tick} />
                <span>OVER 150+ EXPERT COACHES</span>
              </div>

              <div>
                <img src={tick} />
                <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
              </div>
              
              <div>
                <img src={tick} />
                <span>1 FREE PROGRAM FOR NEW MEMBER</span>
              </div>
              
              <div>
                <img src={tick} />
                <span>RELIABLE PARTNERS</span>
              </div>

        </div>

<span style={{
  color : 'var(--gray)',
  fontWeight:'normal'
}}>OUR PARTNERS</span>

    <div className='partners'>
      <img src={nb} />
      <img src={nike} />
      <img src={adidas} />

    </div>

        </div>
        
    </div>
  )
}

export default Reason