import React from "react";
import Header from "../Header/Header";
import "./Hero.css";
import hero_image from '../../picture/hero_image.png' 
import hero_image_back from '../../picture/hero_image_back.png' 
import Heart from '../../picture/heart.png' 
import Calories from '../../picture/calories.png' 
import {motion} from 'framer-motion'
// import counter from 'react-number-counter'

function Hero() {


  const transition={type:'spring',duration : 3}
  const mobile=window.innerWidth<=768 ? true : false;

  return (
    <div className="hero" id="home">
      <div className="blur blur-h"></div>
      <div className="left-h">
        <Header />

        <div className="the-best-ad">
          <motion.div initial={{left:mobile?'150px':"200px"}}
          whileInView={{left:'8px'}}
          transition={{...transition,type:'tween'}}>

            
          </motion.div>
          <span>Best Fitness club in the town</span>
        </div>

        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>

          <div >
            <span>
            In here we will help you to shape and build your ideal body and live up your
            life to fullest
           </span>
          </div>
        
        </div>

        <div className="figures">

          <div> 
            <span>+15
           {/* <counter end={150} start={99} delay="4" preFix="+" /> */}
              </span>  
            <span> Expert Coaches</span>
          </div>
          
          
          <div> 
            <span>+345
            {/* <counter end={919} start={515} delay="4" preFix="+" /> */}

              </span>  
            <span> members joined</span>
          </div>
          
          
          <div> 
            <span>+25
            {/* <counter end={50} start={11} delay="4" preFix="+" /> */}

              </span>  
            <span> fitness program</span>
          </div>
        
        </div>

<div className="hero-button">
  <button className="btn">Get Started</button>
  <button className="btn">Learn More</button>


</div>

      </div>

      <div className="right-h">

        <button className="btn">Join Now!!</button>



        <motion.div className="heart-rate"
          initial={{right:'-1rem'}}
          whileInView={{right:'4rem'}}
          transition={transition}
        >

          <img src={Heart} />
          <span>Heart rate</span>
          <span>115 bpm</span>
        </motion.div>

        <img src={hero_image} className="hero-image" />
        <motion.img 
          initial={{right:'11rem'}}
          whileInView={{right:'24rem'}}
        transition={transition}

        
        src={hero_image_back} className="hero-image-back" />

        <motion.div className="calories"
        initial={{right:'40rem'}}
        whileInView={{right:'30rem'}}
        transition={transition}

        >
          <img src={Calories} />

         <div>
        <span>Calories Burned</span>
        <span>220 kcal</span>
          </div> 


        </motion.div>
      </div>
    </div>
  );
}

export default Hero;
