import Gochikar from "../picture/Gochikar.jpg";
import Yatindar from "../picture/Yatindar.jpg";
import Priya from "../picture/Priya.jpg";


export const testimonialsData = [
  {
    image: Gochikar,
    review:
      "Health is the most precious thing in the world for an individual. Its loss may cause destruction of one’s whole life. So it is recommended to take proper precautions for its sustention. Here are some important health and fitness tips to make the life healthy and fit",
    name: 'Anil Gochikar',
    status : 'Mr. India'
  },
  {
    image: Yatindar,
    review: "Physical activity that increases your heart rate improves blood flow, and a well-oxygenated brain is a healthy brain",
    name: 'Yatinder  Singh',
    status: 'Fitness Icon '
  },
  {
    image : Priya,
    review:'Motivation is what gets you started, but making things a habit is how you keep the longevity of an active lifestyle',
    name: 'Priya Singh',
    status: "Body Builder"
  }
];